import React from 'react';
import DisclaimerCard from '../../components/DisclaimerCard';
import usePageview from '../../hooks/usePageview';

const Disclaimers = () => {
	usePageview('/disclaimers');

	return (
		<>
			<div className='container'>
				<div className='row'>
					<DisclaimerCard
						type={'Cancellation Policy'}
						description={`Last minute cancellations have a huge negative impact on our business. We ask that you please reschedule or cancel at least 24 hours before the beginning of your appointment. If you must cancel or reschedule within 24 hours of your appointment, you will forfeit your deposit. If you are more than 60 minutes late for your appointment and we have not heard from you, your appointment will be cancelled and your deposit will be forfeited. Make sure you let us know if you are running late! \n\nAdditionally, we plan for a specific amount of time for each service. If you need to make changes to your service, please give us 24 hours notice. Any downgrades to your scheduled service that are made within 24 hours of the appointment start time will incur a service change fee of :, due at the time that the service has been completed.`}
						price={' $25.00'}
					/>
					<DisclaimerCard 
						type={'Service Time Estimates'}
						description={'All total service time estimates are based on an average job time and will vary based on the condition of your vehicle.'}
					/>
					<DisclaimerCard
						type={'Pet Hair'}
						description={`Removing pet hair requires extra time and will therefore incur a fee of :. If pet hair was not mentioned during scheduling, the fee will be added at the time of the service if necessary. Whether or not this fee will be applied to your service is at the discretion of your Car Care Specialist.`}
						price={` $35.00`}
					/>
					<DisclaimerCard type={'Bodily Fluid Cleanup'} description={`Any vehicle that requires the cleaning of human or animal bodily fluids will incur a : charge in addition to the regular price of the detail service.`}
					price={` $50.00 `} />
					<DisclaimerCard type={'Stains and Odors'} description={'While we utilize very effective methods for removing stains and odors from your vehicle, not all stains and odors are removable. Ultimately our success will depend on what originally caused them and how long they have been allowed to set in the vehicle. For this reason, we will always do our best to remove stains and odors from your vehicle, but we do not guarantee results. :'} price={'(Even with an Ozone treatment)'} />
					<DisclaimerCard type={'Additional Cleaning Required'} description={`A fee of : will be applied to any vehicle that is determined to need additional cleaning due to an excessive amount of dirt or grime. Whether or not your vehicle requires additional cleaning is at the discretion of your Car Care Specialist.`} 
					price={` $50.00 `} />
					<DisclaimerCard type={'Driveways and Garage Floors'} description={`None of the chemicals we use during our process will stain your driveway or garage floor. However, we have no control over what substances we might be removing from your vehicle while we clean it. Please note that substances such as grease, oil, etc. could potentially leave marks on your driveway or garage floor when they are rinsed off your vehicle. We are not responsible for any stains left on the surface where the vehicle was parked during your detail service. If you are concerned about possible stains, please request that our team move your vehicle to a safer location nearby for the service.`} />
					<DisclaimerCard type={'3rd Row Seats'} description={'All vehicles with 3 rows of seats will be priced as Full Size SUVs.'} />
					<DisclaimerCard type={'Gift Cards'} description={'All gift card sales are final.'} />
				</div>
			</div>
		</>
	);
};

export default Disclaimers;
