import React from 'react';
import Description from './Description';

const InShopDetails = () => {
    return (
        <>
            <Description 
                descStyle={'home-services-desc-lg'}
                text={
                    'Consider scheduling an in-shop service! Benefits include:'
                }
            /> 
            <ul className="in-shop">
                <li><span className="in-shop-bold">15% discount</span> on all applicable detail services with promo code: <span className="in-shop-bold">SHOP</span></li>
                <li><span className="in-shop-bold">Sooner/same-day</span> availability</li>
                <li><span className="in-shop-bold">Climate controlled</span> waiting area</li>
                <li><span className="in-shop-bold">Work desk with second monitor available</span> so you can work while you wait</li>
                <li>Vehicle <span className="in-shop-bold">pick-up/drop-off service</span> available</li>
            </ul>
            <br />
            <Description 
                descStyle={'home-services-desc-lg'}
                text={
                    'Additionally:'
                }
            /> 
            <ul className="in-shop">
                <li><span className="in-shop-bold">Not all services are available mobile</span>.</li>
                <li>Specific services like <span className="in-shop-bold">Ceramic Coatings</span> and <span className="in-shop-bold">Ozone Treatments</span> will need to be scheduled <span className="in-shop-bold">in-shop</span>.</li>
                <li>You can book more than one vehicle in a single<span className="in-shop-bold"> mobile </span>appointment and receive a <span className="in-shop-bold">10% discount</span>. Use promo code: <span className="in-shop-bold">MULTI</span></li>
            </ul>
            <Description 
                descStyle={'home-services-desc-prominent'}
                text={
                    'Please ~ if you need any assistance booking!'
                }
                tel={'call us'}
            />
            <br />
        </>
    )
}

export default InShopDetails;