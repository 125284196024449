import React from 'react';

const ContactInfo = () => {

	return (
		<div className='alert alert-warning'>
			<span className='ci-icon'>
				<a className='ci-icon contactLink' href='tel:(602) 601-2677'>
					<i className='ci-icon fas fa-phone' />{' '}(602) 601-2677
				</a>{' '}
				<span className='ci-dot' id={'phone-dot'}>
					{' '}
					∙{' '}
				</span>
			</span>
			<span className='ci-icon'>
				<a
					className='ci-icon contactLink'
					href='mailto: phoenix@effortlessmobileauto.com'>
					<i className='ci-icon fas fa-envelope' size='5x' />{' '}phoenix@effortlessmobileauto.com
				</a>{' '}
				<span className='ci-dot' id='email-dot'>
					{' '}
					∙{' '}
				</span>
			</span>
			<span className='ci-icon'>
				<a className='ci-icon contactLink' href='https://www.google.com/maps/place/Effortless+Mobile+Auto+Services/@33.4262886,-112.0418996,17z/data=!3m1!4b1!4m5!3m4!1s0x872b0e82fd48c835:0xe49e3e2d9e6f616a!8m2!3d33.4263476!4d-112.0397116'>
					<span id="addr1"><i className='ci-icon fas fa-location-arrow' />{' '}1950 E Watkins St #110{' '}</span><span id="addr2">Phoenix, AZ 85034</span>
				</a>
			</span>
		</div>
	);
};

export default ContactInfo;
