import React from 'react';
import CarouselItem from './CarouselItem';

const Carousel = ({ id, slides }) => {
	const indicators = [];

	for (var i=0; i < slides.length; i++) {
		indicators.push(i);
	}
	
	return (
		<>
			<div id={id} className='carousel slide' data-ride='carousel'>
				<ol className='carousel-indicators'>
					{indicators.map((i) => (
						<li key={i} data-target={`#${id}`} data-slide-to={i} className={i === 0 ? 'active' : null}></li>
					))}
				</ol>
				<div className='carousel-inner'>
					{slides.map((slide,i) => (
						<CarouselItem slide={slide} key={i} i={i} />
					))}
				</div>
			</div>
		</>
	);
};

export default Carousel;
