import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ uri, alt, url }) => {
	return (
		<a className='navbar-brand' href={url}>
			<img
				src={uri}
				className='socialIcon'
				alt={alt}
				height='40'
				loading='eager'
			/>
		</a>
	);
};

Icon.propTypes = {
	uri: PropTypes.any.isRequired,
	alt: PropTypes.string.isRequired,
	url: PropTypes.string.isRequired,
};

export default Icon;
