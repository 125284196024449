import React, { useState, useEffect } from 'react';
import Title from '../components/Title';
import AppointmentButton from '../components/AppointmentButton';
import ExpertiseBadge from '../components/ExpertiseBadge';
import TestimonialCard from '../components/TestimonialCard';
import usePageview from '../hooks/usePageview';
import Spinner from '../components/Spinner';

const Testimonials = () => {
	const [reviews, setReviews] = useState([{ reviews: [] }]);
	const [loading, setLoading] = useState(true);

	usePageview('/testimonials');

	const fetchData = async () => {
		const proxyUrl = 'https://ancient-bastion-25891.herokuapp.com/'
		const apiUrl = 'https://pete.tines.com/webhook/effortless/api?type=reviews';
		let res;
		let data;
		let r;

		res = await fetch(proxyUrl + apiUrl);
		data = await res.json();
		r = data.result.reviews;
		r.sort((a, b) => {
			return b.time - a.time;
		});
		setReviews(r);
		setLoading(false);
	};

	useEffect(() => {
		fetchData();
	}, []);

	let width = window.innerWidth;

	if (loading) {
		return (
			<>
				{ width < 992 && <AppointmentButton />}
				<Spinner />
			</>
		);
	} else {
		return (
			<>
				{ width < 992 && <AppointmentButton />}
				<div className="row">
					<div className='col-sm-12'>
						<ExpertiseBadge home={false} />
					</div>
				</div>
				<div className='row'>
					<Title heading={`Check Out What Our Customers Have to Say About Us!`} />
					{reviews.map((review, i) => (
						<TestimonialCard key={i} review={review} i={i} />
					))}
					<a
						className='moreReviews btn btn-warning'
						href='https://www.google.com/search?q=effortless+mobile+auto+services&amp;rlz=1C1ASUT_enUS528US528&amp;oq=eff&amp;aqs=chrome.1.69i60j69i59j69i61l2j69i60j69i65.1468j0j4&amp;sourceid=chrome&amp;ie=UTF-8#lrd=0x872b0e82fd48c835:0xe49e3e2d9e6f616a,1,,,'>
						See More Google Reviews!
					</a>
				</div>
			</>
		);
	}
};

export default Testimonials;
