import React from 'react';
import { Link } from 'react-router-dom';

const AppointmentButton = () => {
    const onClick = () => {
        const menuToggle = document.getElementById('navbarSupportedContent')
        menuToggle.className = 'navbar-collapse justify-content-end collapse'
    }

    return (
        <button
            id='appt'
            className='btn btn-warning navbar-brand link'
            onClick={onClick}>
            <Link to='/services/carservices'>Make an Appointment </Link>
        </button>
    );
}

export default AppointmentButton;
