import React from 'react';
import Description from './Description';
import PropTypes from 'prop-types'

const TestimonialCard = ({review, i}) => {
	const convertTime = date => {
		let d = new Date(date * 1000);
		const months = [
			'Jan',
			'Feb',
			'Mar',
			'Apr',
			'May',
			'Jun',
			'Jul',
			'Aug',
			'Sep',
			'Oct',
			'Nov',
			'Dec'
		];
		let time =
			months[d.getMonth()] + ' ' + d.getDate() + ', ' + d.getFullYear();
		return time;
	};

	const renderStars = rating => {
		let stars = [];

		for (let i = 0; i < rating; i++) {
			stars.push(<i className='fas fa-star' id={i} key={i}></i>);
		}
		return stars;
	};
	
	return (
		<div key={i} className='col-sm-12'>
			<div className='card'>
				<div className='card-body'>
					<h1 className='rating'>{renderStars(review.rating)}</h1>
					<h3>
						{review.author_name} <span className='test-dash'>- </span>
						<span className='test-date'>
							{convertTime(review.time)}
						</span>
					</h3>
				<Description text={review.text} />
				</div>
			</div>
		</div>
	);
};

TestimonialCard.propTypes ={
	review: PropTypes.any.isRequired,
	i: PropTypes.any.isRequired
};

export default TestimonialCard;
