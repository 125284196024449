import React from 'react';
import Description from './Description';
import Icon from './Icon';
import fb from '../images/logos/newfblogo.png';
import ig from '../images/logos/newinstalogo.png';
import linkedin from '../images/logos/newlinkedinlogo.png';
import google from '../images/logos/fivestar.png';
/* 
import ReactGA from 'react-ga'
import Title from './Title';
import PromoCode from './PromoCode'; 
*/

const Footer = () => {
	/*
	const gaEvent = (serviceName) => {
		return ReactGA.event({
			category: 'click',
			action: 'link',
			label: `${serviceName}`,
		});
	};
	*/

	return (
		<>
			<footer className='page-footer font-small black'>
				<div className='footer-copyright text-center py-3'>
					<div className='row'>
						<div className='col-sm-12'>
							<Description
								text={
									'© Copyright: Effortless Mobile Auto Services. April 24th, 2016. All Rights Reserved.'
								}
								descStyle={'gift-cards'}
							/>
							<p>
								<a
									className='policies'
									href='https://www.privacypolicies.com/privacy/view/bf077009d4260f2f6e0ffaf00c1a60a2'>
									Privacy Policy
								</a>
							</p>
						</div>
					</div>
					<div className='row'>
						<div className='col-sm-12'>
							<Icon
								uri={fb}
								alt={'facebook'}
								url={'https://www.facebook.com/effortlessmobileauto'}
							/>
							<Icon
								uri={ig}
								alt={'instagram'}
								url={'https://www.instagram.com/effortlessmobileauto'}
							/>
							<Icon
								uri={linkedin}
								alt={'linkedin'}
								url={'https://www.linkedin.com/company/effortlessmobileauto'}
							/>
							<Icon
								uri={google}
								alt='google'
								url={
									'https://www.google.com/search?q=effortless+mobile+auto+services&rlz=1C1ASUT_enUS528US528&oq=eff&aqs=chrome.1.69i60j69i59j69i61l2j69i60j69i65.1468j0j4&sourceid=chrome&ie=UTF-8#lrd=0x872b0e82fd48c835:0xe49e3e2d9e6f616a,1,,,'
								}
							/>
						</div>
					</div>
				</div>
			</footer>
		</>
	);
};

export default Footer;

/* 								
<p className='gift-cards'>
	Use promo code <PromoCode code='XMASDETAIL2019' /> for 10%
	off!
</p>
*/
