import React, {useEffect} from 'react';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import Services from './pages/Services';
import Testimonials from './pages/Testimonials';
import Employment from './pages/Employment';
import NotFound from './pages/NotFound';
import './App.css';

function App() {
	ReactGA.initialize('UA-125840994-1');
	ReactPixel.init('752094285669209')

	/*
	const Microsoft = () => {
		const script = document.createElement('script');
		script.async = true;
		script.innerText = `(function(w,d,t,r,u){var f,n,i;w[u]=w[u]||[],f=function(){var o={ti:"17534497"};o.q=w[u],w[u]=new UET(o),w[u].push("pageLoad")},n=d.createElement(t),n.src=r,n.async=1,n.onload=n.onreadystatechange=function(){var s=this.readyState;s&&s!=="loaded"&&s!=="complete"||(f(),n.onload=n.onreadystatechange=null)},i=d.getElementsByTagName(t)[0],i.parentNode.insertBefore(n,i)})(window,document,"script","//bat.bing.com/bat.js","uetq");`
		document.head.appendChild(script)
	}

	const MicrosoftConversion = () => {
		const lpBookLinks = document.querySelectorAll('a[href="https://square.site/appointments/book/"]');
		if (lpBookLinks) {
		lpBookLinks.forEach(function(item) {
			item.addEventListener('click', function handler() {
			window.uetq = window.uetq || []; 
			window.uetq.push({ 'ec':'Link', 'ea':'Click', 'el':'Booking Link'});
			this.removeEventListener('click', handler);
			});
		});
		}
	}
	*/

	const MetaConversionsAPI = async () => {
		const proxyUrl = 'https://ancient-bastion-25891.herokuapp.com/'
		const apiUrl = 'https://pete.tines.com/webhook/effortless/api?type=meta-conversions&event=pageview';

		let res = await fetch(proxyUrl + apiUrl, {
			method: 'POST'
		});
		return res.status
	};

	const Facebook = () => {
		return ReactPixel.fbq('track', 'PageView');
	}

	useEffect(() => {
		Facebook()
		MetaConversionsAPI()
	}, [])

	return (
		<>
			<Router>
				<ScrollToTop>
					<Navbar />
					<div className='container container-sm container-md'>
						<Switch>
							<Route exact path='/' component={Home} />
							<Route path='/services' component={Services} />
							<Route
								path='/testimonials'
								component={Testimonials}
							/>
							<Route path='/employment' component={Employment} />
							<Route component={NotFound} />
						</Switch>
					</div>
					<Footer />
				</ScrollToTop>
			</Router>
		</>
	);
}

export default App;
