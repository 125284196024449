import React from 'react';
import AppointmentButton from '../components/AppointmentButton';

const NotFound = () => {
	let width = window.innerWidth;

	return (
		<>
			{ width < 992 && <AppointmentButton />}
			<div>
				<h1 className='service'>404: Page Not Found</h1>
			</div>
		</>
	);
};

export default NotFound;
