import React from 'react';
import ReactPixel from 'react-facebook-pixel';
import Title from '../../components/Title';
import Description from '../../components/Description';
import usePageview from '../../hooks/usePageview';
import abc from '../../images/for-businesses/IMG_20190706_072956639_HDR.jpg';

const FleetServices = () => {
	usePageview('/fleetservices');

	const fbEvent = () => {
		return ReactPixel.fbq('track', 'Lead');
	}

	return (
		<>
			<div className='container'>
				<Title heading={'We Service Fleets!'} alert={true} />
				<div className='col-sm'>
					<Description
						text={'We offer a variety of fleet services - from basic maintenance cleaning to full interior/exterior detailing and disinfecting. Fleet services are always offered at a custom price based on the number and size of the vehicles in your fleet.'}
					/>
					<Description text={'Call or email us today to receive a free customized estimate!'} />
					<a href='mailto:phoenix@effortlessmobileauto.com'>
						<button className='btn btn-warning' onClick={() => {fbEvent();}}>Email for a Quote</button>
					</a>
				</div>
				<br />
				<img src={abc} alt='abc' id='abc' />
			</div>
		</>
	);
};

export default FleetServices;
