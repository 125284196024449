import React from 'react';
import Description from './Description';

const CarouselItem = ({ slide, i }) => {
	return (
		<div
			className={i === 0 ? 'carousel-item active' : 'carousel-item'}
			data-interval='5000'>
			<img
				src={Object.values(slide.uri)}
				className='d-block w-100 h-100'
				alt={slide.alt}
				loading='eager'
			/>
			{slide.title && slide.caption ? (
				<div className='carousel-caption'>
					<h5 className='carousel-title'>{slide.title}</h5>
					<Description text={slide.caption} descStyle={'carousel-desc'} bold={slide.bold} />
				</div>
			) : (
				<div></div>
			)
			}
		</div>
	);
};

export default CarouselItem;
