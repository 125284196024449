import React, { useState } from 'react';
import usePageview from '../../hooks/usePageview';
import ServiceCard from '../../components/ServiceCard';
import Description from '../../components/Description';
import Title from '../../components/Title';

const TintingServices = () => {
	usePageview('/tintingservices');

	const url = null;
	const toggle = null;
	const label = 'Make an Appointment Link';
	const [carbonFilmToggle, setCarbonFilmToggle] = useState(toggle);
	const [carbonFilmLabel, setCarbonFilmLabel] = useState(label);
	const [cxpFilmToggle, setCxpFilmToggle] = useState(toggle);
	const [cxpFilmLabel, setCxpFilmLabel] = useState(label);
	const [ceramicFilmToggle, setCeramicFilmToggle] = useState(toggle);
	const [ceramicFilmLabel, setCeramicFilmLabel] = useState(label);
	const [evolveFilmToggle, setEvolveFilmToggle] = useState(toggle);
	const [evolveFilmLabel, setEvolveFilmLabel] = useState(label);
	const [headlightToggle, setHeadlightToggle] = useState(toggle);
	const [taillightToggle, setTaillightToggle] = useState(toggle);
	const [windshieldVisorToggle, setWindshieldVisorToggle] = useState(toggle);
	const [windshieldVisorLabel, setWindshieldVisorLabel] = useState(label);

	const onChange = (e, serviceName) => {
		e.preventDefault();
		switch (serviceName) {
			case 'carbonFilm':
				if (
					e.nativeEvent.target[e.nativeEvent.target.selectedIndex].label !==
					'Vehicle'
				) {
					setCarbonFilmLabel(
						e.nativeEvent.target[
							e.nativeEvent.target.selectedIndex
						].label.split(':')[0] + ' Appointment'
					);
				} else {
					setCarbonFilmLabel(label);
				}
				break;
			case 'cxpFilm':
				if (
					e.nativeEvent.target[e.nativeEvent.target.selectedIndex].label !==
					'Vehicle'
				) {
					setCxpFilmLabel(
						e.nativeEvent.target[
							e.nativeEvent.target.selectedIndex
						].label.split(':')[0] + ' Appointment'
					);
				} else {
					setCxpFilmLabel(label);
				}
				break;
			case 'ceramicFilm':
				if (
					e.nativeEvent.target[e.nativeEvent.target.selectedIndex].label !==
					'Vehicle'
				) {
					setCeramicFilmLabel(
						e.nativeEvent.target[
							e.nativeEvent.target.selectedIndex
						].label.split(':')[0] + ' Appointment'
					);
				} else {
					setCeramicFilmLabel(label);
				}
				break;
			case 'evolveFilm':
				if (
					e.nativeEvent.target[e.nativeEvent.target.selectedIndex].label !==
					'Vehicle'
				) {
					setEvolveFilmLabel(
						e.nativeEvent.target[
							e.nativeEvent.target.selectedIndex
						].label.split(':')[0] + ' Appointment'
					);
				} else {
					setEvolveFilmLabel(label);
				}
				break;
			case 'windshieldVisor':
				if (
					e.nativeEvent.target[e.nativeEvent.target.selectedIndex].label !==
					'Vehicle'
				) {
					setWindshieldVisorLabel(
						e.nativeEvent.target[
							e.nativeEvent.target.selectedIndex
						].label.split(':')[0] + ' Appointment'
					);
				} else {
					setWindshieldVisorLabel(label);
				}
				break;
			default:
				return null;
		}
	};

	const onToggle = (e, serviceName) => {
		let toggle = e.target.value;
		switch(serviceName){
			case 'carbonFilm':
				setCarbonFilmToggle(toggle);
				break;
			case 'cxpFilm':
				setCxpFilmToggle(toggle);
				break;
			case 'ceramicFilm':
				setCeramicFilmToggle(toggle);
				break;
			case 'evolveFilm':
				setEvolveFilmToggle(toggle);
				break;
			case 'headlights':
				setHeadlightToggle(toggle);
				break;
			case 'taillights':
				setTaillightToggle(toggle);
				break;
			case 'windshieldVisor':
				setWindshieldVisorToggle(toggle);
				break;
			default:
				return "mobile"
		}
	}

	const setUrl = (serviceName, label) => {
		switch(serviceName){
			case 'carbonFilm':
				if (carbonFilmToggle === 'in-shop') {
					if (label === 'Coupe/Sedan Appointment') {
						return ('https://squareup.com/appointments/book/ad4d9e78-b1f9-46a3-9291-8e0fd5e87b22/M3FRY82K2TX7B/start?_savt=3b899cbc-0787-4641-b58b-f4b42aa04b0b&service_id=TWEQ55VXXCKQAF2GJBEHRDAU')
					}
					if (label === 'Truck/SUV/Tesla Appointment') {
						return ('https://squareup.com/appointments/book/ad4d9e78-b1f9-46a3-9291-8e0fd5e87b22/M3FRY82K2TX7B/start?_savt=3b899cbc-0787-4641-b58b-f4b42aa04b0b&service_id=QF6P3XB6CPN37S6CXTYGUZPL')
					}
				}
				else if (carbonFilmToggle === 'mobile') {
					if (label === 'Coupe/Sedan Appointment') {
						return ('https://squareup.com/appointments/book/ad4d9e78-b1f9-46a3-9291-8e0fd5e87b22/M3FRY82K2TX7B/start?_savt=3b899cbc-0787-4641-b58b-f4b42aa04b0b&service_id=TWEQ55VXXCKQAF2GJBEHRDAU')
					}
					if (label === 'Truck/SUV/Tesla Appointment') {
						return ('https://squareup.com/appointments/book/ad4d9e78-b1f9-46a3-9291-8e0fd5e87b22/M3FRY82K2TX7B/start?_savt=3b899cbc-0787-4641-b58b-f4b42aa04b0b&service_id=QF6P3XB6CPN37S6CXTYGUZPL')
					}
				}
				else {
					return null;
				}
				break;
			case 'cxpFilm':
				if (cxpFilmToggle === 'in-shop') {
					if (label === 'Coupe/Sedan Appointment') {
						return ('https://squareup.com/appointments/book/ad4d9e78-b1f9-46a3-9291-8e0fd5e87b22/M3FRY82K2TX7B/start?_savt=3b899cbc-0787-4641-b58b-f4b42aa04b0b&service_id=5R2UKFWVCELREGKVBBMKPQ6T')
					}
					if (label === 'Truck/SUV/Tesla Appointment') {
						return ('https://squareup.com/appointments/book/ad4d9e78-b1f9-46a3-9291-8e0fd5e87b22/M3FRY82K2TX7B/start?_savt=3b899cbc-0787-4641-b58b-f4b42aa04b0b&service_id=JMRK223H5ODH3WP66UWFKBBT')
					}
				}
				else if (cxpFilmToggle === 'mobile') {
					if (label === 'Coupe/Sedan Appointment') {
						return ('https://squareup.com/appointments/book/ad4d9e78-b1f9-46a3-9291-8e0fd5e87b22/M3FRY82K2TX7B/start?_savt=3b899cbc-0787-4641-b58b-f4b42aa04b0b&service_id=5R2UKFWVCELREGKVBBMKPQ6T')
					}
					if (label === 'Truck/SUV/Tesla Appointment') {
						return ('https://squareup.com/appointments/book/ad4d9e78-b1f9-46a3-9291-8e0fd5e87b22/M3FRY82K2TX7B/start?_savt=3b899cbc-0787-4641-b58b-f4b42aa04b0b&service_id=JMRK223H5ODH3WP66UWFKBBT')
					}
				}
				else {
					return null;
				}
				break;
			case 'ceramicFilm':
				if (ceramicFilmToggle === 'in-shop') {
					if (label === 'Coupe/Sedan Appointment') {
						return ('https://squareup.com/appointments/book/ad4d9e78-b1f9-46a3-9291-8e0fd5e87b22/M3FRY82K2TX7B/start?_savt=3b899cbc-0787-4641-b58b-f4b42aa04b0b&service_id=V2KSFBTP7CMOFKX6HODD7M6O')
					}
					if (label === 'Truck/SUV/Tesla Appointment') {
						return ('https://squareup.com/appointments/book/ad4d9e78-b1f9-46a3-9291-8e0fd5e87b22/M3FRY82K2TX7B/start?_savt=3b899cbc-0787-4641-b58b-f4b42aa04b0b&service_id=SPZ5MYOU7QPAWZTS6ZK4MVN6')
					}
				}
				else if (ceramicFilmToggle === 'mobile') {
					if (label === 'Coupe/Sedan Appointment') {
						return ('https://squareup.com/appointments/book/ad4d9e78-b1f9-46a3-9291-8e0fd5e87b22/M3FRY82K2TX7B/start?_savt=3b899cbc-0787-4641-b58b-f4b42aa04b0b&service_id=V2KSFBTP7CMOFKX6HODD7M6O')
					}
					if (label === 'Truck/SUV/Tesla Appointment') {
						return ('https://squareup.com/appointments/book/ad4d9e78-b1f9-46a3-9291-8e0fd5e87b22/M3FRY82K2TX7B/start?_savt=3b899cbc-0787-4641-b58b-f4b42aa04b0b&service_id=SPZ5MYOU7QPAWZTS6ZK4MVN6')
					}
				}
				else {
					return null;
				}
				break;
			case 'evolveFilm':
				if (evolveFilmToggle === 'in-shop') {
					if (label === 'Coupe/Sedan Appointment') {
						return ('https://squareup.com/appointments/book/ad4d9e78-b1f9-46a3-9291-8e0fd5e87b22/M3FRY82K2TX7B/start?service_id=AMBJOZCWFPXBU2VLX5ZBOXTL')
					}
					if (label === 'Truck/SUV/Tesla Appointment') {
						return ('https://squareup.com/appointments/book/ad4d9e78-b1f9-46a3-9291-8e0fd5e87b22/M3FRY82K2TX7B/start?service_id=MIXLMKTJPP2OUVKVUVHCYFII')
					}
				}
				else if (evolveFilmToggle === 'mobile') {
					if (label === 'Coupe/Sedan Appointment') {
						return ('https://squareup.com/appointments/book/ad4d9e78-b1f9-46a3-9291-8e0fd5e87b22/M3FRY82K2TX7B/start?service_id=AMBJOZCWFPXBU2VLX5ZBOXTL')
					}
					if (label === 'Truck/SUV/Tesla Appointment') {
						return ('https://squareup.com/appointments/book/ad4d9e78-b1f9-46a3-9291-8e0fd5e87b22/M3FRY82K2TX7B/start?service_id=MIXLMKTJPP2OUVKVUVHCYFII')
					}
				}
				else {
					return null;
				}
				break;
			case 'headlights':
				if (headlightToggle === 'in-shop') {
					if (label === 'All Headlights Appointment') {
						return ('https://squareup.com/appointments/book/ad4d9e78-b1f9-46a3-9291-8e0fd5e87b22/M3FRY82K2TX7B/start?_savt=3b899cbc-0787-4641-b58b-f4b42aa04b0b&service_id=ABH4CYGYMD43AWHDC5QVPHNN')
					}
				}
				else if (headlightToggle === 'mobile') {
					if (label === 'All Headlights Appointment') {
						return ('https://squareup.com/appointments/book/ad4d9e78-b1f9-46a3-9291-8e0fd5e87b22/M3FRY82K2TX7B/start?_savt=3b899cbc-0787-4641-b58b-f4b42aa04b0b&service_id=ABH4CYGYMD43AWHDC5QVPHNN')
					}
				}
				else {
					return null;
				}
				break;
			case 'taillights':
				if (taillightToggle === 'in-shop') {
					if (label === 'All Taillights Appointment') {
						return ('https://squareup.com/appointments/book/ad4d9e78-b1f9-46a3-9291-8e0fd5e87b22/M3FRY82K2TX7B/start?_savt=3b899cbc-0787-4641-b58b-f4b42aa04b0b&service_id=M2IBXGL2BTQ7NT2V5AVIZWXJ')
					}
				}
				else if (taillightToggle === 'mobile') {
					if (label === 'All Taillights Appointment') {
						return ('https://squareup.com/appointments/book/ad4d9e78-b1f9-46a3-9291-8e0fd5e87b22/M3FRY82K2TX7B/start?_savt=3b899cbc-0787-4641-b58b-f4b42aa04b0b&service_id=M2IBXGL2BTQ7NT2V5AVIZWXJ')
					}
				}
				else {
					return null;
				}
				break;
		case 'windshieldVisor':
			if (windshieldVisorToggle === 'in-shop') {
				if (label === 'All Vehicles Appointment') {
					return ('https://squareup.com/appointments/book/ad4d9e78-b1f9-46a3-9291-8e0fd5e87b22/M3FRY82K2TX7B/start?_savt=3b899cbc-0787-4641-b58b-f4b42aa04b0b&service_id=MGTKIIIPIZ6FNYDW3U7FBJ5A')
				}
			}
			else if (windshieldVisorToggle === 'mobile') {
				if (label === 'All Vehicles Appointment') {
					return ('https://squareup.com/appointments/book/ad4d9e78-b1f9-46a3-9291-8e0fd5e87b22/M3FRY82K2TX7B/start?_savt=3b899cbc-0787-4641-b58b-f4b42aa04b0b&service_id=MGTKIIIPIZ6FNYDW3U7FBJ5A')
				}
			}
			else {
				return null;
			}
			break;
		default:
			return null;
		}
	}

	return (
		<div>
			<Title heading={'Tinting Services'} alert={true} />
			<Description
				descStyle={'home-services-desc'}
				text={
					'We install locally sourced SunTek films to give your vehicle the highest degree of protection from the harsh Arizona sun. All of our films are metal-free, fade/crack resistant, and installed with a lifetime warranty. Tint installations are offered in-shop or mobile at your location!'
				}
			/>
			<Description
				descStyle={'home-services-desc'}
				text={
					'Pricing includes tinting all windows and rear windshield. If you do not need all windows tinted please say so in the appointment notes and we will follow-up with you to provide a reduced price!'
				}
			/>
			<Description
				descStyle={'home-services-desc-prominent'}
				text={
					'All mobile tint appointments will require a garage to be available to shelter the vehicle from the wind while we work.'
				}
			/>
			<div className='row'>
				<ServiceCard
					colStyle='col-sm-3 col-md-12 col-lg-3'
					title='Carbon Film'
					bullets={[
						'Available in any darkness',
						'Blocks 99% of harmful UV rays',
						'Lifetime warranty',
					]}
					pricing={[
						{
							vehicle: 'Coupe/Sedan: $240',
							url:
								'https://squareup.com/appointments/book/ad4d9e78-b1f9-46a3-9291-8e0fd5e87b22/M3FRY82K2TX7B/start?_savt=3b899cbc-0787-4641-b58b-f4b42aa04b0b&service_id=TWEQ55VXXCKQAF2GJBEHRDAU',
						},
						{
							vehicle: 'Truck/SUV/Tesla: $340',
							url:
								'https://squareup.com/appointments/book/ad4d9e78-b1f9-46a3-9291-8e0fd5e87b22/M3FRY82K2TX7B/start?_savt=3b899cbc-0787-4641-b58b-f4b42aa04b0b&service_id=QF6P3XB6CPN37S6CXTYGUZPL',
						},
					]}
					onChange={(e) => onChange(e, 'carbonFilm')}
					onToggle={(e) => onToggle(e, 'carbonFilm')}
					location={'tempe'}
					url={setUrl('carbonFilm', carbonFilmLabel) || url}
					label={carbonFilmLabel}
					tint={true}
					specialPadding={true}
				/>
				<ServiceCard
					colStyle='col-sm-3 col-md-12 col-lg-3'
					title='CXP Film'
					bullets={[
						'Available in any darkness',
						'Blocks 99% of harmful UV rays',
						'Rejects up to 70% of solar energy to reduce glare and maintain vehicle temperature',
						'Protects vehicle’s interior and passengers from harmful infrared rays',
						'Lifetime warranty',
					]}
					pricing={[
						{
							vehicle: 'Coupe/Sedan: $340',
							url:
								'https://squareup.com/appointments/book/ad4d9e78-b1f9-46a3-9291-8e0fd5e87b22/M3FRY82K2TX7B/start?_savt=3b899cbc-0787-4641-b58b-f4b42aa04b0b&service_id=5R2UKFWVCELREGKVBBMKPQ6T',
						},
						{
							vehicle: 'Truck/SUV/Tesla: $440',
							url:
								'https://squareup.com/appointments/book/ad4d9e78-b1f9-46a3-9291-8e0fd5e87b22/M3FRY82K2TX7B/start?_savt=3b899cbc-0787-4641-b58b-f4b42aa04b0b&service_id=JMRK223H5ODH3WP66UWFKBBT',
						},
					]}
					onChange={(e) => onChange(e, 'cxpFilm')}
					onToggle={(e) => onToggle(e, 'cxpFilm')}
					location={'tempe'}
					url={setUrl('cxpFilm', cxpFilmLabel) || url}
					label={cxpFilmLabel}
					tint={true}
				/>
				<ServiceCard
					colStyle='col-sm-3 col-md-12 col-lg-3'
					title='Ceramic Film'
					bullets={[
						'Available in any darkness',
						'Blocks 99% of harmful UV rays',
						'Rejects up to 90% of solar energy to reduce glare and maintain vehicle temperature',
						'Protects vehicle’s interior and passengers from harmful infrared rays',
						'Lifetime warranty',
					]}
					pricing={[
						{
							vehicle: 'Coupe/Sedan: $540',
							url:
								'https://squareup.com/appointments/book/ad4d9e78-b1f9-46a3-9291-8e0fd5e87b22/M3FRY82K2TX7B/start?_savt=3b899cbc-0787-4641-b58b-f4b42aa04b0b&service_id=V2KSFBTP7CMOFKX6HODD7M6O',
						},
						{
							vehicle: 'Truck/SUV/Tesla: $640',
							url:
								'https://squareup.com/appointments/book/ad4d9e78-b1f9-46a3-9291-8e0fd5e87b22/M3FRY82K2TX7B/start?_savt=3b899cbc-0787-4641-b58b-f4b42aa04b0b&service_id=SPZ5MYOU7QPAWZTS6ZK4MVN6',
						},
					]}
					onChange={(e) => onChange(e, 'ceramicFilm')}
					onToggle={(e) => onToggle(e, 'ceramicFilm')}
					location={'tempe'}
					url={setUrl('ceramicFilm', ceramicFilmLabel) || url}
					label={ceramicFilmLabel}
					tint={true}
				/>
				<ServiceCard
					colStyle='col-sm-3 col-md-12 col-lg-3'
					title='Evolve Film'
					bullets={[
						'Available in any darkness',
						'Blocks 99% of harmful UV rays',
						'Rejects up to 95% of solar energy to reduce glare and maintain vehicle temperature',
						'Protects vehicle’s interior and passengers from harmful infrared rays',
						'Lifetime warranty',
					]}
					pricing={[
						{
							vehicle: 'Coupe/Sedan: $600',
							url:
								'https://squareup.com/appointments/book/ad4d9e78-b1f9-46a3-9291-8e0fd5e87b22/M3FRY82K2TX7B/start?_savt=3b899cbc-0787-4641-b58b-f4b42aa04b0b&service_id=V2KSFBTP7CMOFKX6HODD7M6O',
						},
						{
							vehicle: 'Truck/SUV/Tesla: $700',
							url:
								'https://squareup.com/appointments/book/ad4d9e78-b1f9-46a3-9291-8e0fd5e87b22/M3FRY82K2TX7B/start?_savt=3b899cbc-0787-4641-b58b-f4b42aa04b0b&service_id=SPZ5MYOU7QPAWZTS6ZK4MVN6',
						},
					]}
					onChange={(e) => onChange(e, 'evolveFilm')}
					onToggle={(e) => onToggle(e, 'evolveFilm')}
					location={'tempe'}
					url={setUrl('evolveFilm', evolveFilmLabel) || url}
					label={evolveFilmLabel}
					tint={true}
				/>
				</div>
				<div className="row">
					<ServiceCard 
						colStyle='col-sm-4 col-md-12 col-lg-4 centered'
						title='Windshield Visor'
						pricing={[
							{
								vehicle: 'All Vehicles: $35',
								url: 'https://squareup.com/appointments/book/ad4d9e78-b1f9-46a3-9291-8e0fd5e87b22/M3FRY82K2TX7B/start?_savt=3b899cbc-0787-4641-b58b-f4b42aa04b0b&service_id=MGTKIIIPIZ6FNYDW3U7FBJ5A'
							},
						]}
						onChange={(e) => onChange(e, 'windshieldVisor')}
						onToggle={(e) => onToggle(e, 'windshieldVisor')}
						url={setUrl('windshieldVisor', windshieldVisorLabel) || url}
						label={windshieldVisorLabel}
						tint={true}
					/>
				</div>
		</div>
	);
};

export default TintingServices;
