import React from 'react';
import PropTypes from 'prop-types';

const InShopDetailsHome = () => {
    return (
        <>
            <ul className="in-shop">
                <li><span className="in-shop-bold">15% discount</span> on all applicable detail services with promo code: <span className="in-shop-bold">SHOP</span></li>
                <li><span className="in-shop-bold">Sooner/same-day</span> availability</li>
                <li><span className="in-shop-bold">Climate controlled</span> waiting area</li>
                <li><span className="in-shop-bold">Work desk with second monitor available</span> so you can work while you wait</li>
                <li>Vehicle <span className="in-shop-bold">pick-up/drop-off service</span> available</li>
            </ul>
        </>
    )
}

InShopDetailsHome.propTypes = {
	services: PropTypes.bool.isRequired,
};

export default InShopDetailsHome;