import React from 'react';
import { Link } from 'react-router-dom';
import Title from './Title';
import Description from './Description';
import Carousel from './Carousel';
import GalleryImage from './GalleryImage';
import paintBefore from '../images/paint-correction/1.jpg';
import paintAfter from '../images/paint-correction/2.jpg';
import edit from '../images/tinting/edit.jpg';
import edit1 from '../images/tinting/edit1.jpg';
import edit2 from '../images/tinting/tint3.PNG';
import edit3 from '../images/tinting/edit3.jpg';
import waterBefore from '../images/water-spots/before.jpg';
import waterAfter from '../images/water-spots/after.jpg';

const HomePageServices = () => {
	let width = window.innerWidth;
	let tintingSlides = [
		{
			uri: { edit },
			alt: 'Porsche Tinting',
		},
		{
			uri: { edit1 },
			alt: 'F150 Tinting',
		},
		{
			uri: { edit2 },
			alt: 'G-Wagon Tinting',
		},
		{
			uri: { edit3 },
			alt: 'Honda Tinting',
		}
	];

	/*
		<Link to='/services/paintservices'>
			<button className='btn btn-warning'>Click Here to Learn More</button>
		</Link>
	*/

	return (
		<>
			<div className='home-services'>
				<div className='row'>
					<div className='col-sm-12'>
						<Title heading={'Mobile & In-Shop Tinting Services'} />
						<Description
							descStyle={'home-services-desc'}
							text={
								'We offer SUNTEK® Carbon, CXP, and Ceramic films in your preferred darkness to keep your ride cool and give it the look you need!'
							}
						/>
						<Link to='/services/tintingservices'>
							<button className='btn btn-warning'>Click Here to Learn More</button>
						</Link>
					</div>
				</div>
				{width >= 768 ? (
					<>
						<div className='row'>
							<GalleryImage
								uri={edit}
								alt={'Porsche Tinting'}
								colStyle={'col-sm-4 col-md-6'}
								imgStyle={'home-services-image'}
							/>
							<GalleryImage
								uri={edit3}
								alt={'Honda Tinting'}
								colStyle={'col-sm-4 col-md-6'}
								imgStyle={'home-services-image'}
							/>
						</div>
					</>
				) : (
					<Carousel id={'tintingCarousel'} slides={tintingSlides}/>
				)}
				<div className="row">
					<div className="col-sm-12">
						<Title heading={"We Can Fix This!"} />
						<Description 
							descStyle={"home-services-desc"} 
							text={'Has your vehicle been contaminated with hard water spots? No problem! We will remove them with our Water Spot Removal add-on service. When booking online, simply book any Exterior or Full Car detail service and add it on!'}
						/>
					</div>
				</div>
				<div className="row">
					<GalleryImage
						uri={waterBefore}
						alt={'Water Spots Before Correction'}
						colStyle={'col-sm-4 col-md-6'}
						imgStyle={'home-services-image'}
						desc={'Water Spots (Before)'}
					/>
					<GalleryImage
						uri={waterAfter}
						alt={'Water Spots After Correction'}
						colStyle={'col-sm-4 col-md-6'}
						imgStyle={'home-services-image'}
						desc={'Water Spots (After)'}
					/>
				</div>
				<div className='row'>
					<div className='col-sm-12'>
						<Description
							descStyle={`home-services-desc`}
							text={
								'Swirls ruining your finish? Try our ~ with any ~ for light swirl correction and improved gloss, or ask about multi-step paint correction to restore a mirror finish!'
							}
							bold={'Full Body Polish + Clay Bar add-on'}
							bold2={'Exterior or Full Car detail service'}
						/>
					</div>
				</div>
				<div className='row'>
					<GalleryImage
						uri={paintBefore}
						alt={'Audi Before Paint Correction'}
						colStyle={'col-sm-4 col-md-6'}
						imgStyle={'home-services-image'}
						desc={'Before Paint Correction'}
					/>
					<GalleryImage
						uri={paintAfter}
						alt={'Audi After Paint Correction'}
						colStyle={'col-sm-4 col-md-6'}
						imgStyle={'home-services-image'}
						desc={'After Paint Correction'}
					/>
				</div>
			</div>
			<br />
		</>
	);
};

export default HomePageServices;
