import React from 'react';
import PropTypes from 'prop-types';

const DisclaimerCard = ({ type, description, price, price2 }) => {
	return (
		<>
			<div className='col-sm-3 col-md-12'>
				<div className='card'>
					<div className='card-body'>
						<h5 className='card-title'>{type}</h5>
						<p className='disclaimer'>
							{description.split(':')[0]}
							<span className='disclaimer'>{price}</span>
							{description.split(':')[1]}
							<span className='disclaimer'>{price2}</span>
							{description.split(':')[2]}
						</p>
					</div>
				</div>
			</div>
		</>
	);
};

DisclaimerCard.propTypes = {
	type: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
	price: PropTypes.any,
};

export default DisclaimerCard;
