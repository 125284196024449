import React from 'react';
import Title from './Title';
import Description from './Description'
import InShopDetailsHome from './InShopDetailsHome';

const PromoVideo = () => {
	return (
		<div id='promoVideo'>
			<div className='embed-responsive embed-responsive-16by9'>
				<iframe
					title='Effortless Detailing in Action'
					className='embed-responsive-item'
					src='https://www.youtube.com/embed/t52xidYVj2Q?autoplay=1&mute=1'
					frameBorder='0'
					allow='autoplay'></iframe>
			</div>
			<div className='carousel-caption'>
				<Title heading={'We Offer Both Mobile AND In-Shop Detailing Services!'} />
				<Description text={'Mobile'} descStyle={'small-heading'}/>
				<Description text={"We'll come to your home, office, or any other location that is convenient for you to offer interior, exterior, polishing, and engine bay detailing services that will make your vehicle shine! We'll also help you protect your vehicle from the harsh Arizona elements with a variety of high quality sealants, dressings, conditioners, and carnauba waxes."} descStyle={'carousel-desc'} />
				<Description text={'In-Shop'} descStyle={'small-heading'}/>
				<Description text={"In addition to the convenience offered by our mobile services, we also provide the option for you to bring your vehicle to our shop! ~:"} descStyle={'carousel-desc'} bold={'Benefits include'} />
				<InShopDetailsHome />
			</div>
			<br /><br />
		</div>
	);
};

/*<GiftCards />*/

export default PromoVideo;
