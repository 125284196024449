import React from 'react';
import Title from './Title';
import trustanalytica2024 from '../images/logos/trustanalytica2024.png';
import phoenixreview from '../images/logos/phoenixreview.png'

const ExpertiseBadge = (home) => {
    return (
        <>
            <Title heading={`We're Proud to be Recognized as a Top Auto Detailer by Expertise.com and Trust Analytica for 3 Years Straight!`} specialClass={'expertiseBadge'} />
            <div className='row'>
                <div className="expertiseBadge col-sm-4">
                    <a href="https://www.expertise.com/az/phoenix/car-detailing" id="badge">
                        <img id ="badgeImg" src="https://res.cloudinary.com/expertise-com/image/upload/f_auto,fl_lossy,q_auto/w_auto/remote_media/awards/az_phoenix_car-detailing_2024_transparent.svg" alt="Expertise.com" />
                    </a>
                </div>
                <div className="expertiseBadge col-sm-4">
                    <a href="https://trustanalytica.com/us/az/phoenix/reviews/effortless-mobile-auto-services" id="badge">
                        <img id ="trustAnalytica" src={trustanalytica2024} alt="trustanalytica.com" />
                    </a>
                </div>
                <div className="expertiseBadge col-sm-4">
                    <a href="https://www.thephoenixreview.com/top-window-tinting-phoenix/" id="badge">
                        <img id ="phoenixReview" src={phoenixreview} alt="thephoenixreview.com" />
                    </a>
                </div>
            </div>
        </>
    )
}

export default ExpertiseBadge;