import React from 'react';

const Description = ({ text, descStyle, bold, bold2, address, tel }) => {
	return (
		<>
			<p className={descStyle}>
				{text.split('~')[0] }
				{bold && <span className='disclaimer'>{bold}</span>}
				{tel && <a className='ci-icon contactLink' href='tel:(602) 601-2677'>{tel}</a>}
				{text.split('~')[1] || text.split('|')[1] }
				{bold2 && <span className='disclaimer'>{bold2}</span>}
				{text.split('~')[2]}
				{address && <a className="carousel-desc" href='https://www.google.com/maps/place/Effortless+Mobile+Auto+Services/@33.4262886,-112.0418996,17z/data=!3m1!4b1!4m5!3m4!1s0x872b0e82fd48c835:0xe49e3e2d9e6f616a!8m2!3d33.4263476!4d-112.0397116'>{address}</a>}
			</p>
		</>
	);
};

export default Description;
