import React from 'react';
import PropTypes from 'prop-types'

const Title = ({ heading, specialty, alert, specialClass, id }) => {
	return (
		<div className={specialClass ? specialClass : null} id={id}>
			<div className={alert ? 'alert alert-warning' : null} id={alert ? 'alert' : null}>
				<h1 className='service'>{heading}</h1>
				{specialty ? <h6 className='service'>{specialty}</h6> : null}
			</div>
		</div>
	);
};

Title.propTypes = {
	heading: PropTypes.string.isRequired,
	specialty: PropTypes.string,
	alert: PropTypes.bool,
	id: PropTypes.string
};

export default Title;
