import {useEffect} from 'react'
import ReactGA from 'react-ga';

export default url => {
		function pageView(url) {
			return ReactGA.pageview(url);
		}

		useEffect((url) => {
			pageView(`${url}`)
		}, [])
};
