import React from 'react';
import PropTypes from 'prop-types';

const GalleryImage = ({ uri, alt, colStyle, imgStyle, desc, id }) => {
	return (
		<div className={colStyle}>
			<img src={uri} className={imgStyle} alt={alt} loading='auto' id={id} />
			{desc ? <p className='hps-desc'>{desc}</p> : null}
		</div>
	);
};

GalleryImage.propTypes = {
	uri: PropTypes.any.isRequired,
	alt: PropTypes.string.isRequired,
	colStyle: PropTypes.string,
	imgStyle: PropTypes.string,
	desc: PropTypes.string,
	id: PropTypes.string,
};

export default GalleryImage;
