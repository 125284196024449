import React from 'react';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import PropTypes from 'prop-types';

const ServiceCard = ({
	colStyle,
	title,
	hq,
	bullets,
	pricing,
	url,
	label,
	onChange,
	onToggle,
	location,
	ceramic,
	specialty,
	specialtyPrice,
	email,
	bold,
	specialPadding,
}) => {
	const gaEvent = () => {
		/*
		return ReactGA.event({
			category: 'click',
			action: 'link',
			label: `${serviceName}`,
		});
		*/
		return ReactGA.ga('send', 'event', 'link', 'click', `Make An Appointment Link`)
	};

	const fbEvent = () => {
		return ReactPixel.fbq('track', 'Lead');
	}

	return (
		<div className={colStyle}>
			<div className='card'>
				<div className='card-body'>
					<h5 className='card-title'>
						{title}
						{hq ? <span className='hq'>(Highest Quality)</span> : null}
						{specialty ? (
							<span>
								{' '}
								{specialtyPrice && (
									<span className='specialty'>{specialtyPrice}</span>
								)}
							</span>
						) : null}
					</h5>
					{bold ? <h6 className="card-title"><span className='bold'>{bold}</span></h6> : null}
					{bullets ? (
						<>
							<ul className='card-text'>
								{bullets.map((bullet, i) => (
									<li key={i}>{bullet}</li>
								))}
							</ul>

						</>
					) : null}
					{specialPadding ? <h5 className='card-title-115'><span className='card-title-115-text'></span></h5> : null}
					{pricing ? (
						<select className='btn card-text pricing' onChange={onChange}>
							<option value=''>Vehicle</option>
							{pricing.map((price, i) => (
								<option className="dropdown-text" key={i} value={price.url} label={price.vehicle}>
									{price.vehicle}
								</option>
							))}
						</select>
					) : null}
					{onToggle && ceramic ? <select className='btn card-text location' onChange={onToggle}>
						<option className="dropdown-text" value=''>Location</option>
						<option className="dropdown-text" value='in-shop'>In-Shop Only</option>
					</select> : null}
					{onToggle && !ceramic ?
						<select className='btn card-text location' onChange={onToggle}>
							<option className="dropdown-text" value=''>Location</option>
							{location === 'tempe' ? <option className="dropdown-text" value='in-shop'>Our Tint Shop (Tempe, AZ)</option> : <option className="dropdown-text" value='in-shop'>Our Shop (15% Off)</option>}
							{location === 'tempe' ? <option className="dropdown-text" value='mobile'>Your Address (+$30)</option> : <option className="dropdown-text" value='mobile'>Your Address</option>}
						</select>
						: null}
					{url === null && onToggle && !specialty ? <p className="card-text toggle">Please select a vehicle & location</p> : null}
					{specialty && !email && !url ? <p className="card-text toggle">Please select a location</p> : null}
					{email ? (
						<a
							href={'mailto:phoenix@effortlessmobileauto.com'}
							onClick={() => {
								fbEvent();
								gaEvent(`${title} ${label}`);
							}}
							className='serviceButton btn btn-warning'
						>
							Email for a Quote
						</a>
					) : null}
					{url ? (
						<a
							href={url}
							onClick={() => {
								fbEvent();
								gaEvent(`${title} ${label}`);
							}}
							className='serviceButton btn btn-warning'
						>
							Book Now
						</a>
					) : null}
				</div>
			</div>
		</div>
	);
};

ServiceCard.propTypes = {
	colStyle: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	hq: PropTypes.bool,
	bullets: PropTypes.array,
	pricing: PropTypes.array,
	onChange: PropTypes.func,
	onToggle: PropTypes.func,
	url: PropTypes.string,
	label: PropTypes.string,
	specialty: PropTypes.bool,
	specialtyPrice: PropTypes.string,
	email: PropTypes.bool,
	bold: PropTypes.string,
	tint: PropTypes.bool
};

export default ServiceCard;
