import React from 'react';
import Title from '../components/Title';
import Description from '../components/Description';
import AppointmentButton from '../components/AppointmentButton';
import usePageview from '../hooks/usePageview';
import team from '../images/employment/careers.jpg';

const Employment = () => {
	usePageview('/employment');

	let width = window.innerWidth;

	return (
		<>
			{ width < 992 && <AppointmentButton />}
			<div className='container'>
				<Title heading={'Effortless is Hiring!'} />
				<Description
					text={
						"Are you looking for flexible ~ employment? We're looking to expand our team and we offer ~. Feel free to contact us using any of the methods listed on this page for more information"
					}
					bold={'full-time or part-time'}
					bold2={'flexible scheduling, competitive compensation, and satisfying work'}
				/>
				<Description
					descStyle={'employment'}
					text={
						"We will provide hands-on training as well as mentorship and all of the other tools that you will need to succeed. A valid driver's license is required in order to be eligible."
					}
				/>
				<div className='row'>
					<div className='col-sm'>
						<a href='https://form.jotform.com/92768548817173'>
							<button
								type='button'
								className='btn btn-warning'
								data-toggle='modal'
								data-target='#employmentModal'>
								Join the team!
							</button>
						</a>
					</div>
				</div>
				<br />
				<div className='row'>
					<div className='col-sm-12'>
						<img src={team} loading='auto' alt='careers' id='team' />
					</div>
				</div>
			</div>
		</>
	);
};

export default Employment;
