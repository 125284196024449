import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ContactInfo from './ContactInfo';
import logo from '../images/logos/newlogo.jpg';

const Navbar = () => {
	const [menu, setMenu] = useState(false);

	const hbClick = () => {
		setMenu(true);
	};

	const onClick = () => {
		setMenu(false);
	};

	const show = menu ? 'show' : '';

	let width = window.innerWidth;

	return (
		<>
			<nav className='navbar navbar-expand-lg navbar-dark fixed-top bg-dark'>
				<div className='container'>
					<Link className='navbar-brand' to='/'>
						<img
							src={logo}
							alt='logo'
							width='210'
							height='75'
							loading='eager'
						/>
						<span id='logo-desc'>Mobile Auto Detailing</span>
					</Link>
					<button
						className='navbar-toggler ml-auto custom-toggler'
						type='button'
						data-toggle='collapse'
						data-target='#navbarSupportedContent'
						aria-controls='navbarSupportedContent'
						aria-expanded='false'
						aria-label='Toggle navigation'
						onClick={hbClick}>
						<span className='navbar-toggler-icon' />
					</button>
					<div
						className={'navbar-collapse justify-content-end collapse ' + show}
						id='navbarSupportedContent'>
						<ul className='navbar-nav'>
							{
							width >= 992 ?
							<button
								id='appt'
								className='btn btn-warning navbar-brand link'
								onClick={onClick}>
								<Link to='/services/carservices'>Make an Appointment </Link>
							</button>
							:
							<Link
								className='navbar-brand link'
								to='/services/carservices'
								onClick={onClick}>
								Services
							</Link>
							}
							<i className='navbar-brand link'>∙</i>
							<Link
								className='navbar-brand link'
								to='/testimonials'
								onClick={onClick}>
								Testimonials
							</Link>
							<i className='navbar-brand link'>∙</i>
							<Link
								className='navbar-brand link'
								to='/employment'
								onClick={onClick}>
								Careers
							</Link>
						</ul>
					</div>
				</div>
			</nav>
			<ContactInfo />
		</>
	);
};

export default Navbar;
