import React from 'react';

const Spinner = () => {
	return (
		<>
			<div id='spinner' className='row'>
				<div className='col-sm-12'>
					<i className='fas fa-cog fa-10x fa-spin'></i>
				</div>
			</div>
		</>
	);
};

export default Spinner;
